import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import logoLightSvg from '../../assets/Images/Icon/mainLogo.svg';

const NotFound = () => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                {/* <img src={logoLightSvg} alt="" height="50" /> */}
                <h1 className="display-2 font-weight-medium">
                  4
                  <i
                    className="bx bx-buoy bx-spin primary-text display-3"
                    // style={{ color: "#ca2d07" }}
                  />
                  4
                </h1>
                <h4 className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn primary-btn waves-effect waves-light"
                    to="/"
                  >
                    Back to Home
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
