import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../helpers/api_helper";
import {
  getTrustedPartner,
  getTrustedPartnerFail,
  getTrustedPartnerSuccess,
} from "./commonSlice";

function* onGetTrustedPartner() {
  try {
    const url = `/centro-landing-page/get-trusted-partner`;
    const response = yield call(get, url);

    if (response) {
      yield put(getTrustedPartnerSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get trusted partner failed!";
    yield put(getTrustedPartnerFail({ message }));
  }
}

export function* commonSaga() {
  yield takeEvery(getTrustedPartner.type, onGetTrustedPartner);
}
