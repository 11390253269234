import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  trustedPartner: {},
  error: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    getTrustedPartner: (state) => {
      state.loading = true;
    },
    getTrustedPartnerSuccess: (state, action) => {
      state.loading = false;
      state.trustedPartner = action.payload;
    },
    getTrustedPartnerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getTrustedPartner,
  getTrustedPartnerFail,
  getTrustedPartnerSuccess,
} = commonSlice.actions;

export default commonSlice.reducer;
