import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  homeData: {},
  aboutData: {},
  processData: {},
  productData: {},
  sustainabilityData: {},
  historyData: {},
  leadershipData: {},
  asEmployeeData: {},
  asPartnerData: {},
  blogData: [],
  singleBlogData: {},
  blogTitleData: {},
  visionariesData: [],
  jobData: [],
  cultureData: [],
  singleJobData: {},
  responsibilityData: [],
  singleResponsibilityData: {},
  error: "",
};

const homeDataSlice = createSlice({
  name: "homeData",
  initialState,
  reducers: {
    getHomeData: (state) => {
      state.loading = true;
    },
    getHomeDataSuccess: (state, action) => {
      state.loading = false;
      state.homeData = action.payload;
    },
    getHomeDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getAboutData: (state) => {
      state.loading = true;
    },
    getAboutDataSuccess: (state, action) => {
      state.loading = false;
      state.aboutData = action.payload;
    },
    getAboutDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getProcessData: (state) => {
      state.loading = true;
    },
    getProcessDataSuccess: (state, action) => {
      state.loading = false;
      state.processData = action.payload;
    },
    getProcessDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getProductData: (state) => {
      state.loading = true;
    },
    getProductDataSuccess: (state, action) => {
      state.loading = false;
      state.productData = action.payload;
    },
    getProductDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getSustainabilityData: (state) => {
      state.loading = true;
    },
    getSustainabilityDataSuccess: (state, action) => {
      state.loading = false;
      state.sustainabilityData = action.payload;
    },
    getSustainabilityDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getHistoryData: (state) => {
      state.loading = true;
    },
    getHistoryDataSuccess: (state, action) => {
      state.loading = false;
      state.historyData = action.payload;
    },
    getHistoryDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },

    getLeadershipData: (state) => {
      state.loading = true;
    },
    getLeadershipDataSuccess: (state, action) => {
      state.loading = false;
      state.leadershipData = action.payload;
    },
    getLeadershipDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getAsEmployeeData: (state) => {
      state.loading = true;
    },
    getAsEmployeeDataSuccess: (state, action) => {
      state.loading = false;
      state.asEmployeeData = action.payload;
    },
    getAsEmployeeDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getAsPartnerData: (state) => {
      state.loading = true;
    },
    getAsPartnerDataSuccess: (state, action) => {
      state.loading = false;
      state.asPartnerData = action.payload;
    },
    getAsPartnerDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getVisionariesData: (state) => {
      state.loading = true;
    },
    getVisionariesSuccess: (state, action) => {
      state.loading = false;
      state.visionariesData = action.payload;
    },
    getVisionariesFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getBlogData: (state) => {
      state.loading = true;
    },
    getBlogDataSuccess: (state, action) => {
      state.loading = false;
      state.blogData = action.payload;
    },
    getBlogDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getSingleBlogData: (state) => {
      state.loading = true;
    },
    getSingleBlogDataSuccess: (state, action) => {
      state.loading = false;
      state.singleBlogData = action.payload;
    },
    getSingleBlogDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getJobData: (state) => {
      state.loading = true;
    },
    getJobDataSuccess: (state, action) => {
      state.loading = false;
      state.jobData = action.payload;
    },
    getJobDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getSingleJobData: (state) => {
      state.loading = true;
    },
    getSingleJobDataSuccess: (state, action) => {
      state.loading = false;
      state.singleJobData = action.payload;
    },
    getSingleJobDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    applyExistingJob: (state, action) => {},
    applyOpenCv: (state, action) => {},

    getCultureData: (state) => {
      state.loading = true;
    },
    getCultureSuccess: (state, action) => {
      state.loading = false;
      state.cultureData = action.payload;
    },
    getCultureFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getResponsibilityData: (state) => {
      state.loading = true;
    },
    getResponsibilityDataSuccess: (state, action) => {
      state.loading = false;
      state.responsibilityData = action.payload;
    },
    getResponsibilityDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getSingleResponsibilityData: (state) => {
      state.loading = true;
    },
    getSingleResponsibilityDataSuccess: (state, action) => {
      state.loading = false;
      state.singleResponsibilityData = action.payload;
    },
    getSingleResponsibilityDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getBlogTitleData: (state) => {
      state.loading = true;
    },
    getBlogTitleDataSuccess: (state, action) => {
      state.loading = false;
      state.blogTitleData = action.payload;
    },
    getBlogTitleDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getHomeData,
  getHomeDataSuccess,
  getHomeDataFail,
  getAboutData,
  getAboutDataSuccess,
  getAboutDataFail,
  getProcessData,
  getProcessDataSuccess,
  getProcessDataFail,
  getProductData,
  getProductDataSuccess,
  getProductDataFail,
  getSustainabilityData,
  getSustainabilityDataSuccess,
  getSustainabilityDataFail,
  getHistoryData,
  getHistoryDataSuccess,
  getHistoryDataFail,
  getLeadershipData,
  getLeadershipDataSuccess,
  getLeadershipDataFail,
  getAsEmployeeData,
  getAsEmployeeDataSuccess,
  getAsEmployeeDataFail,
  getAsPartnerData,
  getAsPartnerDataSuccess,
  getAsPartnerDataFail,
  getVisionariesData,
  getVisionariesSuccess,
  getVisionariesFail,
  getBlogData,
  getBlogDataSuccess,
  getBlogDataFail,
  getSingleBlogData,
  getSingleBlogDataSuccess,
  getSingleBlogDataFail,
  getJobData,
  getJobDataSuccess,
  getJobDataFail,
  getSingleJobData,
  getSingleJobDataSuccess,
  getSingleJobDataFail,
  applyExistingJob,
  applyOpenCv,
  getCultureData,
  getCultureSuccess,
  getCultureFail,
  getResponsibilityData,
  getResponsibilityDataSuccess,
  getResponsibilityDataFail,
  getSingleResponsibilityData,
  getSingleResponsibilityDataSuccess,
  getSingleResponsibilityDataFail,
  getBlogTitleData,
  getBlogTitleDataSuccess,
  getBlogTitleDataFail,
} = homeDataSlice.actions;

export default homeDataSlice.reducer;
