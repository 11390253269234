import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Loader from "../common/Loader";
import Certificates from "../pages/about/Certificates";
import NotFound from "../pages/NotFound";
import SocialResponsibilitiesDetails from "../pages/SocialResponsibilities/SocialResponsibilitiesDetails";
import SustainabilityDetails from "../pages/sustainability/SustainabilityDetails";

// import Sustainability from "../pages/sustainability";
const Leadership = React.lazy(() => import("../pages/Leadership"));
const Process = React.lazy(() => import("../pages/Process/Process"));
const AboutUs = React.lazy(() => import("../pages/about/AboutUs"));
const AsEmployee = React.lazy(() => import("../pages/asEmployee/AsEmployee"));
const AsPartner = React.lazy(() => import("../pages/asPartner/AsPartner"));
const History = React.lazy(() => import("../pages/history"));
const HomePage = React.lazy(() => import("../pages/home"));
const NewsAndUpdates = React.lazy(() => import("../pages/news-and-update"));
const NewsDetails = React.lazy(() => import("../pages/news-details"));
const Products = React.lazy(() => import("../pages/products"));
const Sustainability = React.lazy(() => import("../pages/sustainability"));
const JobPostDetails = React.lazy(() =>
  import("../pages/JobPostDetails/JobPostDetails")
);
const DesignStudio = React.lazy(() =>
  import("../pages/DesignStudio/DesignStudio")
);
const SocialResponsibilities = React.lazy(() =>
  import("../pages/SocialResponsibilities/SocialResponsibilities")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: "about",
    element: (
      <Suspense fallback={<Loader />}>
        <AboutUs />
      </Suspense>
    ),
  },
  {
    path: "history",
    element: (
      <Suspense fallback={<Loader />}>
        <History />
      </Suspense>
    ),
  },
  {
    path: "sustainability",
    element: (
      <Suspense fallback={<Loader />}>
        <Sustainability />
      </Suspense>
    ),
  },
  {
    path: "sustainability/:id",
    element: (
      <Suspense fallback={<Loader />}>
        <SustainabilityDetails />
      </Suspense>
    ),
  },
  {
    path: "product",
    element: (
      <Suspense fallback={<Loader />}>
        <Products />
      </Suspense>
    ),
  },
  {
    path: "leadership",
    element: (
      <Suspense fallback={<Loader />}>
        <Leadership />
      </Suspense>
    ),
  },
  {
    path: "people",
    element: (
      <Suspense fallback={<Loader />}>
        <AsEmployee />
      </Suspense>
    ),
  },
  {
    path: "partner",
    element: (
      <Suspense fallback={<Loader />}>
        <AsPartner />
      </Suspense>
    ),
  },
  {
    path: "process",
    element: (
      <Suspense fallback={<Loader />}>
        <Process />
      </Suspense>
    ),
  },
  {
    path: "recent-highlights",
    element: (
      <Suspense fallback={<Loader />}>
        <NewsAndUpdates />
      </Suspense>
    ),
  },
  {
    path: "design-studio",
    element: (
      <Suspense fallback={<Loader />}>
        <DesignStudio />
      </Suspense>
    ),
  },
  {
    path: "social-responsibilities",
    element: (
      <Suspense fallback={<Loader />}>
        <SocialResponsibilities />
      </Suspense>
    ),
  },
  {
    path: "social-responsibilities/:scrId",
    element: (
      <Suspense fallback={<Loader />}>
        <SocialResponsibilitiesDetails />
      </Suspense>
    ),
  },
  {
    path: "job-post-details/:jobId",
    element: (
      <Suspense fallback={<Loader />}>
        <JobPostDetails />
      </Suspense>
    ),
  },
  {
    path: "news-details/:blogId",
    element: (
      <Suspense fallback={<Loader />}>
        <NewsDetails />
      </Suspense>
    ),
  },
  {
    path: "our-certificates",
    element: (
      <Suspense fallback={<Loader />}>
        <Certificates />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<Loader />}>
        <NotFound />
      </Suspense>
    ),
  },
]);
