import { all, fork } from "redux-saga/effects";
import { commonSaga } from "./Common/saga";
import { contactSaga } from "./Contact/saga";
import { homeSaga } from "./Home/saga";

export default function* rootSaga() {
  yield all([fork(homeSaga)]);
  yield all([fork(contactSaga)]);
  yield all([fork(commonSaga)]);
}
